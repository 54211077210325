.image {
    width: 100%;
    max-height: 100%;
}

.video {
    width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    height: 100dvh;
    z-index: 1;
    background: #0A0A0A;
    object-fit: cover;
}